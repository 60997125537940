@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply border-0 p-2 px-6 bg-slate-100 rounded-md;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Poppins", sans-serif;
}
:root {
  --font_normal: 400;
  --font_medium: 500;
  --font_semibold: 600;
  --font_bold: 700;

  --primary: #ff005b;
  --secondary: #fff8f7;
  --tertiary: #757575;
  --common: #424348;
  --subtitle: #292929;
  --midtext: #251d19;
  --sidebar: #fafafa;
  --greyish: #fff7f7;
  --darkGreyish: #78716d;
}
.container_width {
  width: 85%;
  margin: 0 auto;
  @media screen and (min-width: 268px) and (max-width: 899px) {
    width: 95%;
  }
}

/*height */
// .height_fit {
//   height: fit-content;
// }

/* font size */
.fs-36 {
  font-size: 36px;
}
.fs-34 {
  font-size: 34px;
}
.fs-30 {
  font-size: 30px;
}
.fs-28 {
  font-size: 28px;
}
.fs-26 {
  font-size: 26px;
}
.fs-24 {
  font-size: 24px;
}
.fs-22 {
  font-size: 22px;
}
.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}
.fs-12 {
  font-size: 12px;
}
.fs-11 {
  font-size: 11px;
}
.fs-10 {
  font-size: 10px;
}
/* font weight */
.font_semibold {
  font-weight: var(--font_semibold);
}

.font_medium {
  font-weight: var(--font_medium);
}

.font_bold {
  font-weight: var(--font_bold);
}

.font_normal {
  font-weight: var(--font_normal);
}

/*-------- colors --------*/

.font_color_primary {
  color: var(--primary);
}

.font_color_darkGreyish {
  color: var(--darkGreyish);
}

.font_color_secondary {
  color: var(--secondary);
}

.font_color_tertiary {
  color: var(--tertiary);
}

.font_color_common {
  color: var(--common);
}
.font_color_subtitle {
  color: var(--subtitle);
}
.font_color_midtext {
  color: var(--midtext);
}
.font_color_greyish {
  color: var(--greyish);
}

/*-------- background colors--------*/
.bg_primary {
  background-color: var(--primary);
}

.bg_secondary {
  background-color: var(--secondary);
}
.bg_tertiary {
  background-color: var(--tertiary);
}
.bg_common {
  background-color: var(--common);
}
.bg_sidebar {
  background-color: var(--sidebar);
}

/*-------- border classes --------*/
.btn_primary {
  border: 0.5px solid var(--primary);
  border-radius: 3px;
}

.btn_secondary {
  border: 0.5px solid var(--tertiary);
  border-radius: 3px;
}

.btn_tertiary {
  border: 0.5px solid var(--common);
  border-radius: 13px;
}
.btn_tertiary_1 {
  border: 0.5px solid var(--common);
  border-radius: 3px;
}
.btn_common {
  border: 2px solid var(--common);
  border-radius: 4px;
}

/*------------box shadow -----------*/
.shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.drop_shadow {
  box-shadow: 0px 0px 5px #00000029;
}
.shadow_list {
  box-shadow: 0px 0px 6px #00000029;
}
/*------------Font family -----------*/
.description_text {
  font-family: "Times New Roman", Times, serif;
}
.times {
  font-family: Times, serif;
}
/*------------Editor footer removal -----------*/
.tox-statusbar {
  display: none !important;
}

/*------------comment test -----------*/

.comments {
  margin-top: 20px;
}

.comments-title {
  margin-bottom: 20px;
}

.comments-container {
  margin-top: 40px;
}

.comment-form-title {
  font-size: 22px;
}

.comment-form-textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  margin-top: 8px;
  border: 0px solid rgb(252, 252, 250);
  resize: none;
  outline: none;
}

.comment-form-button {
  background: rgb(255, 255, 255);
  border: 1px solid black;
}

.comment-form-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.comment-form-cancel-button {
  margin-left: 10px;
}

.comment {
  display: flex;
  margin-bottom: 20px;
}

.comment-image-container {
  margin-right: 12px;
}

.comment-image-container img {
  border-radius: 50px;
}

.comment-right-part {
  width: 100%;
}

.comment-content {
  display: flex;
}

.comment-author {
  margin-right: 8px;

  color: rgb(1, 10, 24);
}

.comment-actions {
  display: flex;
  font-size: 12px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  margin-top: 8px;
}

.comment-action {
  margin-right: 8px;
}

.comment-action:hover {
  text-decoration: underline;
}

.replies {
  margin-top: 20px;
}
.truncate_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 43px;
}
.truncate_pub {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 35px;
}
.next-slick-arrow,
.prev-slick-arrow {
  color: #ff005b;
  font-size: 28px;
}

.tinymceContent {
  p {
    font-size: 16px;
    margin: 10px 0px;
    line-height: 22.4px;
  }
  h1 {
    font-size: 36px;
  }
  h3 {
    font-size: 18px;
  }
  h2 {
    font-size: 24px;
  }
  figure {
    margin: 10px 0px;
  }
  figcaption {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #cce8ff;
}

.examplewrapper {
  padding: 16px 120px;
}
.tox .tox-editor-header {
  z-index: 0 !important;
}

// .Toastify__progress-bar {
//   background-color: var(--greyish) !important;
// }

.successful-toast {
  background: #fff !important;
  color: var(--tertiary) !important;
  font-size: 20px !important;
  .Toastify__progress-bar--success {
    background: var(--greyish) !important;
  }
  .Toastify__toast-icon > svg {
    fill: var(--primary) !important;
  }
}

.error-toast {
  background: #fff !important;
  color: #757575 !important;
  font-size: 20px !important;
  .Toastify__progress-bar--success {
    background: red !important;
  }
  .Toastify__toast-icon > svg {
    fill: red !important;
  }
}
