.header_bg {
  background: #fff7f7;
  height:35rem;
  @media screen and (min-width: 900px)   {
    height: 21rem
  }
  
  @media screen and (min-width: 375px) and (max-width:899px) {
    height: 11rem;
    background:white;
  
  }
 
  @media screen and (min-width: 268px) and (max-width:374px) {
    height: 10rem;
   background:white;
    
  }
}
.header_bg1 {
  background: #fff7f7;
  height:5rem;
   @media screen and (min-width: 268px) and (max-width:899px) {
    height: 4rem;
    background:white;
  }
 .header_new {
  height:5rem;
   @media screen and (min-width: 268px) and (max-width:899px) {
    height: 4rem;
    background:white;
  }
 }
  
}
.write_header:hover {
  color: #ff005b;
}
.bell_icon {
  color: black !important;
}
.user_information {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 211px;
  // height: 215px;
  z-index: 10;
  
}
.notification {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  width: 298px;
  // height: 215px;
  z-index: 10;
  
}

