.description_public {
  width: 532px;
  height: 400px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffc5d6;
  opacity: 1;
}
.description_name {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  opacity: 1;
  outline: none;
  height: 55px;
}
.next_btn {
  padding: 7px 53px;
}
.about_name {
  height: 143px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #707070;
  border-radius: 5px;
  opacity: 1;
  outline: none;
}
