.default_btn {
  padding: 5px 8px;

  @media screen and (max-width: 900px) {
    padding: 2.5px 5px;
  }
  @media screen and (max-width: 600px) {
    padding: 5px 12px;
  }
}
.default_btn:hover {
  color: #ff005b;
  border-color: #ff005b;
  opacity: 1;
  cursor: pointer;
}
